import React, { useRef, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useInfiniteHits } from 'react-instantsearch';
import detailsIcon from '../../assets/icons/details.jpg';
import fallbackImage from '../../assets/imgs/np.png';

export default function AthleteTable(props) {
    const { hits: currentPageHits } = useInfiniteHits(props);
    const { role } = props;

    const columns = [
        {
            field: 'image',
            headerName: '',
            width: 75,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <img
                    style={{ width: '35px', borderRadius: '50%' }}
                    src={params.value ? params.value : fallbackImage}
                    alt="profile"
                />
            ),
        },
        {
            field: 'profile',
            headerName: 'LINK',
            width: 75,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                        style={{ color: 'green', textAlign: 'center' }}
                        href={`/recruit/view/${params.row.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img style={{ width: '25px' }} src={detailsIcon} alt="details" />
                    </a>
                </div>
            ),
        },
        { field: 'name', headerName: 'NAME', sortable: false, filterable: false, width: 250 },
        { field: 'year', headerName: 'YEAR', width: 80, sortable: false, filterable: false },
        { field: 'rank', headerName: 'NP RATING', sortable: false, filterable: false, width: 200 },
        { field: 'highSchool', headerName: 'HIGH SCHOOL', sortable: false, filterable: false, width: 300 },
        { field: 'location', headerName: 'ST', sortable: false, filterable: false, width: 50 },
        { field: 'hsCounty', headerName: 'COUNTY', sortable: false, filterable: false, width: 120 },
        { field: 'height', headerName: 'HT', sortable: false, filterable: false, width: 75 },
        { field: 'weight', headerName: 'WT', sortable: false, filterable: false, width: 50 },
        { field: 'offPosition', headerName: 'O', sortable: false, filterable: false, width: 100 },
        { field: 'defPosition', headerName: 'D', sortable: false, filterable: false, width: 100 },
        { field: 'specialTeamsPosition', headerName: 'ST', sortable: false, filterable: false, width: 100 },
        { field: 'scoutPos', headerName: 'SCOUT', sortable: false, filterable: false, width: 100 },
        { field: 'gpa', headerName: 'GPA', sortable: false, filterable: false, width: 50 },
    ];

    if (role && role.User_Role?.type === 'A') {
        columns.push({
            field: 'id',
            headerName: 'Edit',
            sortable: false,
            filterable: false,
            width: 75,
            renderCell: (params) => (
                <a href={`/admin/athlete/${params.value}`} target="_blank" rel="noopener noreferrer">
                    Edit
                </a>
            ),
        });
    }

    const handleRowClick = (params) => {
        if (!role || role.User_Role?.type === 'A') {
            const url = `/recruit/view/${params.id}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <div>
           
            {currentPageHits && currentPageHits.length > 0 ? (
                <div style={{ overflowX: 'auto' }}>
                    <DataGrid
                     sx={{
                        '& .MuiDataGrid-virtualScroller': {
                          transform: 'rotateX(180deg)',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                          transform: 'rotateX(180deg)',
                        },
                        '& .MuiDataGrid-cell': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                color: 'white',
                                backgroundColor: '#026534',
                                fontWeight: 'bold',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1, // Ensure the header stays above other content
                            },
                  }}
                        rows={currentPageHits}
                        density={"comfortable"}
                        rowHeight={45}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        
                    />
                </div>
            ) : null}
        </div>
    );
}
